import Spin from "@/assets/icons/Spin"
import { theme } from "@/styles/theme"
import styled from "styled-components"

const ButtonSizes = {
    normal: {
        padding: "16px 24px",
        height: 48,
    },
    small: {
        padding: "10px 24px",
        height: 36,
    },
}

const ButtonColors = {
    primary: {
        background: theme.palette.green600,
        color: theme.palette.paper,
        backgroundDisabled: theme.palette.violet700,
        colorDisabled: theme.palette.paper,
        loadingCircle: { circleFill: theme.palette.paper, rotatedElementFill: theme.palette.green400 },
        hoverBackground: theme.palette.green700,
    },
    secondary: {
        background: theme.palette.paper,
        color: theme.palette.blue600,
        backgroundDisabled: theme.palette.paper,
        colorDisabled: theme.palette.violet800,
        loadingCircle: { circleFill: theme.palette.violet500, rotatedElementFill: theme.palette.blue600 },
        hoverBackground: theme.palette.violet400,
        border: `2px solid ${theme.palette.violet800}`,
        borderDisabled: `2px solid ${theme.palette.violet700}`,
    },
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    buttonColor?: keyof typeof ButtonColors
    buttonSize?: keyof typeof ButtonSizes
    loading?: boolean
    loadingText?: string
    label: string
}

const StyledButton = styled.button<{ buttonColor?: keyof typeof ButtonColors; buttonSize?: keyof typeof ButtonSizes }>`
    height: ${({ buttonSize }) => ButtonSizes[buttonSize].height}px;
    padding: ${({ buttonSize }) => ButtonSizes[buttonSize].padding};
    cursor: pointer;
    background: ${p => ButtonColors[p.buttonColor].background};
    font-family: ${p => p.theme.fontFamily.rubik};
    font-weight: 500;
    line-height: 100%;
    font-size: ${p => p.theme.fontSizes.sm};
    transition-duration: 300ms;
    color: ${p => ButtonColors[p.buttonColor].color};
    border-radius: 8px;
    border: none;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 8px;
    border: ${p => (p.buttonColor === "secondary" ? ButtonColors[p.buttonColor].border : "default")};
    &:hover {
        background: ${p => ButtonColors[p.buttonColor].hoverBackground};
    }
    &:disabled {
        cursor: not-allowed;
        background: ${p => ButtonColors[p.buttonColor].backgroundDisabled};
        color: ${p => ButtonColors[p.buttonColor].colorDisabled};
        border: ${p => (p.buttonColor === "secondary" ? ButtonColors[p.buttonColor].borderDisabled : "default")};
    }
`

const AnimatedSpin = styled(Spin)`
    animation: spin 1s linear infinite;
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`

export const Button: React.FC<ButtonProps> = ({ buttonColor, buttonSize, label, loading, loadingText, ...props }) => {
    return (
        <StyledButton buttonColor={buttonColor} buttonSize={buttonSize} {...props}>
            {loading && !props.disabled && (
                <AnimatedSpin
                    circleFill={ButtonColors[buttonColor].loadingCircle.circleFill}
                    rotatedElementFill={ButtonColors[buttonColor].loadingCircle.rotatedElementFill}
                />
            )}
            {loading && !props.disabled ? loadingText : label}
        </StyledButton>
    )
}

Button.defaultProps = {
    buttonColor: "primary",
    buttonSize: "normal",
    loading: false,
}
