import React from "react"
import { useTranslation } from "react-i18next"
import NotFoundImage from "@/assets/ilustrations/404_ilustration.svg"
import { Button, CustomHelmet, Flex, Link } from "@/components/common"
import { ServiceBox } from "@/components/common/ServiceBox"
import { Text2 } from "@/components/common/Text2"
import { BaseLayout } from "@/layouts/BaseLayout"
import { paths } from "@/paths"

const NotFound = () => {
    const { t } = useTranslation("errorsCodes")
    return (
        <BaseLayout>
            <CustomHelmet title="404" />
            <ServiceBox
                InfoSectionTitle={t("tipsTitle404")}
                InfoSectionTitleItems={[
                    { text: t("404tip1") },
                    { text: t("404tip2") },
                    {
                        text: t("404tip3_1"),
                        link: <a href={`mailto:support@heroify.co?subject=${"Heroify Error 404"}`}>{t("500tip3_2")}</a>,
                    },
                ]}>
                <Flex col fullW fullH style={{ paddingTop: "12px" }}>
                    <NotFoundImage />
                    <Flex col gap="8px">
                        <Text2 font="h1">{t("pageTitle404")}</Text2>
                        <Text2 font="t1">{t("pageSubTitle404")}</Text2>
                    </Flex>
                    <Link passHref href={paths.hrDashboard.go()}>
                        <a style={{ marginTop: "auto" }}>
                            <Button label={t("backToLogin404Button")}></Button>
                        </a>
                    </Link>
                </Flex>
            </ServiceBox>
        </BaseLayout>
    )
}

export default NotFound
