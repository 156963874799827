import { ReactNode } from "react"
import { LogoIcon, RightArrowAcute } from "@/assets/icons"
import { Flex } from "@/components/common"
import { Text2 } from "@/components/common/Text2"
import styled from "styled-components"

type ServiceBoxType = {
    InfoSectionTitle?: string
    InfoSectionTitleItems?: { text: string; link?: ReactNode }[]
    children: ReactNode
}

const InfoItem = ({ text, link }: { text: string; link?: ReactNode }) => {
    return (
        <Flex align="flex-start" gap="16px">
            <div>
                <RightArrowAcute />
            </div>
            <></>
            <span style={{ lineHeight: "120%" }}>
                <Text2 style={{ display: "inline" }} font="t1">
                    {text}
                </Text2>
                {link && (
                    <Text2 style={{ display: "inline" }} color="blue500" font="t1">
                        {link}
                    </Text2>
                )}
            </span>
        </Flex>
    )
}

const BoxWrapper = styled.div`
    max-width: 1179px;
    width: 100%;
    min-height: 649px;
    border-radius: 8px;
    box-shadow: 0px 38px 35px -25px ${p => p.theme.palette.violet500};
    background: ${p => p.theme.palette.paper};
    display: flex;
    align-content: center;
    justify-content: space-between;
`

const WorkSection = styled.div`
    @media (max-width: ${p => p.theme.breakpoints.sm}px) {
        margin: 20px;
    }
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px;
    background: ${p => p.theme.palette.paper};
`
const InfoSection = styled.div`
    @media (max-width: ${p => p.theme.breakpoints.md}px) {
        display: none;
    }
    border-radius: 0px 8px 8px 0px;
    display: flex;
    max-width: 531px;
    width: 100%;
    padding: 50px 53px;
    background-image: url("/images/service_backgorund.svg");
`
const ContentSection = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`

export const ServiceBox: React.FC<ServiceBoxType> = ({ InfoSectionTitle, InfoSectionTitleItems, children }) => {
    return (
        <Flex justify="center" align="center" fullW fullH>
            <BoxWrapper>
                <WorkSection>
                    <LogoIcon height={24} width={112} />
                    <ContentSection>{children}</ContentSection>
                </WorkSection>
                <InfoSection>
                    <Flex gap="33px" col style={{ marginTop: "auto" }}>
                        <Text2 scale font="h3">
                            {InfoSectionTitle}
                        </Text2>
                        <Flex col gap="27px">
                            {InfoSectionTitleItems &&
                                InfoSectionTitleItems.map(item => (
                                    <InfoItem key={item.text} text={item.text} link={item.link} />
                                ))}
                        </Flex>
                    </Flex>
                </InfoSection>
            </BoxWrapper>
        </Flex>
    )
}
