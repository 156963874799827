import React from "react"
import { Box } from "@/components/common"
import { Layout as _Layout } from "antd"
import styled from "styled-components"

const Content = styled(_Layout.Content)`
    min-height: 100vh;
    height: 100%;
    display: flex;
    overflow-y: auto;
`
const Layout = styled(_Layout)<{ withBackground?: boolean }>`
    height: 100%;
    background: ${p => (p.withBackground ? p.theme.palette.violet300 : "none")};
`

export const BaseLayout = ({
    children,
    withBackground = true,
}: {
    children: React.ReactNode
    withBackground?: boolean
}) => {
    return (
        <Layout withBackground={withBackground}>
            <Content>
                <Box fullW>{children}</Box>
            </Content>
        </Layout>
    )
}
